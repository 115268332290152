/* ==========================================================================
   ANIMATIONS
   ========================================================================== */

@-webkit-keyframes intro {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes intro {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}