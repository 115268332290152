@charset "utf-8";

@import "minimal-mistakes/skins/neon"; // skin

// You can override colors after inclusion of the skin above
$background-color: #030203;
$primary-color: #000000;
$link-color:    #f628cb; 

// Override the code highlighting colors:
$base00: #231e18;
$base01: #302b25;
$base02: #48413a;
$base03: #9d8b70;
$base04: #b4a490;
$base05: #cabcb1;
$base06: #d7c8bc;
$base07: #e4d4c8;
$base08: #d35c5c;
$base09: #ca7f32;
$base0a: #e0ac16;
$base0b: #b7ba53;
$base0c: #6eb958;
$base0d: #88a4d3;
$base0e: #bb90e2;
$base0f: #b49368;

@import "minimal-mistakes"; // main partials

.page__content p,
.archive p {
    text-align: justify;
}

.archive__item {
    height: 200px;
}

.archive__item-teaser {
    float: right;
    max-width: 30%;
    max-height: 150px;
    margin-left: 1em;
}


p::-moz-selection { background-color: #f628cb}
p::selection { background-color: #f628cb; }

.page__footer {
    background-color: #000000;
}
